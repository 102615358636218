<template>
  <div
    id="layout"
    class="antialiased bg-white text-stone-900 font-sans border-b"
  >
    <client-only>
      <NuxtLazyHydrate when-idle>
        <DateRequest />
      </NuxtLazyHydrate>

      <NuxtLazyHydrate when-visible>
        <EventSubscribeToUpdates />
      </NuxtLazyHydrate>
    </client-only>
    <ErrorBoundary>
      <Navbar />
    </ErrorBoundary>
    <client-only>
      <Notifications />
    </client-only>

    <header>
      <slot name="banner" />
    </header>

    <ErrorBoundary>
      <main>
        <div class="max-w-screen-xl m-auto px-4">
          <PageLoader />
          <slot />
        </div>
      </main>
    </ErrorBoundary>

    <slot name="links-section">
      <ErrorBoundary>
        <NuxtLazyHydrate when-visible>
          <EventListLinksBlock />
        </NuxtLazyHydrate>
      </ErrorBoundary>
    </slot>
    <slot name="footer">
      <ErrorBoundary>
        <NuxtLazyHydrate when-visible>
          <Footer :footer-block-items="footerBlock" />
        </NuxtLazyHydrate>
      </ErrorBoundary>
    </slot>
  </div>
</template>
<script lang="ts" setup>
const { locale } = useI18n()

const { data: footerData } = await useStrapiSingleType(
  `footer?locale=${locale.value}&populate[footerBlock][populate]=*`
)
const { footerBlock = [] } = footerData?.value?.data?.attributes || {}

onMounted(async () => {
  const { useGtmPageView } = await import('~/composables/useGtmPageView')
  useGtmPageView()
})

useLayoutHead()
</script>

<style lang="postcss" scoped>
#layout {
  box-sizing: border-box;
}

main {
  position: relative;
  min-height: 100vh;

  &:has(#home) {
    overflow: hidden;
  }
}
</style>
<style>
.Toastify__toast-container {
  max-width: 480px;
  --toastify-toast-width: max-content;
}
</style>
